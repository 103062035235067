<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar
        :title="title"
        left-arrow
        @click-left="$router.replace({ path: '/' })"
      />
    </van-sticky>

    <van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="page.filterData.productClass"
            :options="pclassOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="page.filterData.name"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>
    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="page.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <div class="van-clearfix">
          <div
            class="layout_wrapper"
            style="
              float: left;
              width: 50%;
              text-align: center;
              background-color: #f8f8f8;
              overflow: hidden;
            "
            @click="toDetail(item.id.toString())"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <div class="product-layout__item">
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="item.imageUrl"
              />

              <div style="height: 42px;overflow: hidden; ">
               
                  <h3 style="display: inline-block; height: 21px">
                    {{ item.name }}
                  </h3>
                
              </div>
              <div
                v-if="item.price && item.price != ''"
                style="
                  height: 21px;
                  text-align: left;
                  padding-left: 10px;
                  margin-bottom: 10px;
                "
              >
                <div class="van-ellipsis">
                  <span style="color: #9d1d22"> ¥ </span>
                  <span
                    style="color: #9d1d22; font-size: 18px; font-weight: bold"
                  >
                    {{ item.price }}
                  </span>
                </div>
              </div>
              <div  v-else  style="
                  height: 21px;
                  text-align: left;
                  padding-left: 10px;
                  margin-bottom: 10px;
                ">
<div class="van-ellipsis">
                  <span style="color: #9d1d22">  </span>
                  <span
                    style="color: #9d1d22; font-size: 18px; font-weight: bold"
                  >
                   &nbsp;
                  </span>
                </div>
              
</div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getZNProductList, getZNProductClassList } from "@/api/product";

//const key = "zn-history";
export default {
  name: "znproductlist",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      pclassvalue: 0,

      pclassOption: [{ text: "全部", value: 0 }],

      type: 100,
      title: "产品中心",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        sorterType: 0,
        current: 1,
        pageSize: 10,
        filterData: {
          name: "",
          productClass: 0,
          typeId: 0,
        },
      },
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 1;
      let pClassList = await getZNProductClassList();
      this.pclassOption = [{ text: "全部", value: 0 }];
      console.log(this.pClassOption);

      pClassList.data.data.map((x) =>
        this.pclassOption.push({ text: x.name, value: x.id })
      );
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    toDetail(id) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/product/detail",
        query: { para: id, type: this.page.filterData.productClass },
      });
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getZNProductList({ ...this.page });
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    height: 200px;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.product-layout_wrapper {
}
.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
